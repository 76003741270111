export enum AccessRight {
  NONE = 0,
  VIEW = 1,
  EDIT = 2,
  ADMIN = 3,
  // Facilitator does not have edit access but can view map and report
  FACILITATOR = 4,
}

export const accessRightToString = (accessRight: AccessRight): string => {
  switch (accessRight) {
    case AccessRight.NONE:
      return "None";
    case AccessRight.VIEW:
      return "View";
    case AccessRight.EDIT:
      return "Edit";
    case AccessRight.ADMIN:
      return "Admin";
    case AccessRight.FACILITATOR:
      return "Facilitator";
    default:
      return "Unknown";
  }
};
