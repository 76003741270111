export const tooltips = {
  createPresentation: {
    title: "Create presentation",
    detail: `Create a new presentation for your map`,
  },
  createLesson: {
    title: "Create lesson",
    detail: `Create a new lesson for your map`,
  },
  addFavourite: {
    title: "Add to your favourites",
  },
  removeFavourite: {
    title: "Remove from your favourites",
  },
  share: {
    title: "Share this map/course",
  },
};
