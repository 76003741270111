import { CourseItem } from "../generated/graphql";

export enum CourseItemType {
  CourseModule = "CourseModule",
  Lesson = "LessonItem",
  Quiz = "Quiz",
  RecallItem = "RecallItem",
  FlashcardItem = "FlashcardItem",
}

const getCourseItemType = (courseItem: CourseItem | CourseItemType) => {
  let courseItemType;
  if (typeof courseItem === "string") {
    courseItemType = courseItem;
  } else {
    courseItemType = courseItem.__typename;
  }
  return courseItemType;
};

export const getCourseItemTypeName = (courseItem: CourseItem | CourseItemType): string => {
  switch (getCourseItemType(courseItem)) {
    case CourseItemType.CourseModule:
      return "Module";
    case CourseItemType.Lesson:
      return "Lesson";
    case CourseItemType.Quiz:
      return "Quiz";
    case CourseItemType.RecallItem:
      return "Recall";
    case CourseItemType.FlashcardItem:
      return "Flashcard";
    default:
      return "Unknown";
  }
};

export const getCourseItemTypeVerb = (courseItem: CourseItem | CourseItemType): string => {
  switch (getCourseItemType(courseItem)) {
    case CourseItemType.Lesson:
      return "Watch";
    case CourseItemType.CourseModule:
    case CourseItemType.Quiz:
    case CourseItemType.RecallItem:
    case CourseItemType.FlashcardItem:
      return "Take";
    default:
      return "Unsupported";
  }
};
