import { getMapOrCourseUrl, isMapOrCourse } from "./navigationUtils";

export const deployedBasename = "/app";

/**  NOTES: 
      1. The basename should NOT include the end slash
      2. The server doesn't have process.env.NODE_ENV set 
        so basename would be incorrect if accessed from a development server 
        although it's not currently used on the server.
*/
export const basename =
  process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test" ? "" : deployedBasename;

export function getSiteUrl(path: string): string {
  let url = document.location.origin + basename;
  if (path == null || !path.startsWith("/")) {
    url += "/";
  }
  return url + path;
}

export function getExternalMapOrCourseUrl(mapId?: string, courseId?: string): string | undefined {
  if (!isMapOrCourse(mapId, courseId)) {
    return undefined;
  }
  return getSiteUrl(getMapOrCourseUrl(mapId, courseId));
}
export function getExternalSummaryUrl(mapId?: string, courseId?: string): string | undefined {
  if (!isMapOrCourse(mapId, courseId)) {
    return undefined;
  }
  return `${getExternalMapOrCourseUrl(mapId, courseId)}/summary`;
}

export function getRelativePath(path: string): string {
  let url = basename;
  if (path == null || !path.startsWith("/")) {
    url += "/";
  }
  return url + path;
}
