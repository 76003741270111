import React from "react";
import { UserType } from "../../constant/userType";

type History = {
  push: (path: string, userType: UserType) => void;
};

const defaultHistory: History = {
  push: (path: string) => {
    window.location.href = `/app${path}`;
  },
};

const HistoryContext = React.createContext<History>(defaultHistory);

export default HistoryContext;
