import React, { useContext, useMemo } from "react";
import { List as MuiList, withStyles } from "@material-ui/core";
import { isEmpty } from "lodash";
import styled from "styled-components";
import { useLessonAccess } from "../../../../hooks/useCourseItemAccess";
import { UserType } from "../../../../../constant/userType";
import HistoryContext from "../../../../context/HistoryContext";
import { MapProgress, Map, AnswerAttempt, Course, UserCourseAccess } from "../../../../../generated/graphql";
import SignUpOrPayOverlay from "../SignUpOrPayOverlay";
import CourseModuleItem from "./CourseModuleItem";
import ModuleItem from "./ModuleItem";
import { getModuleProgress } from "./moduleProgress";
import {
  getLockedItems,
  getNumberOfPreviewLessons,
  isCourseProxyForMap,
  isPaidCourse,
} from "../../../../../utils/courseUtils";
import { PaymentOutcome } from "../PaymentOutcome";

const PaymentOutcomeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
`;

const List = withStyles({ root: { backgroundColor: "#f0f0f0", marginTop: 6, marginBottom: 6 } })(MuiList);

export type Props = {
  courseId?: string;
  course?: Course;
  map: Map;
  isLoggedIn?: boolean;
  mapProgress?: MapProgress | null;
  flashcardProgress?: Record<string, { correct: number; attempted: number }> | undefined;
  mapAnswers?: AnswerAttempt[];
  expandedModuleId: string | undefined;
  onToggleModule: (moduleId: string) => void;
  userCourseAccess?: UserCourseAccess;
  outcome?: "paid" | "cancelled";
  onPaymentRequired?: () => void;
};

const CourseItemList: React.FC<Props> = ({
  courseId,
  course,
  map,
  isLoggedIn,
  mapProgress,
  mapAnswers = [],
  flashcardProgress = {},
  expandedModuleId,
  onToggleModule,
  userCourseAccess,
  outcome,
  onPaymentRequired,
}) => {
  const numberOfPreviewLessons = getNumberOfPreviewLessons(course);
  const history = useContext(HistoryContext);
  const { courseItems, presentations } = map;
  const handleLogin = () => {
    history.push(`/login?courseId=${courseId}`, UserType.AllUsers);
  };

  const moduleProgress = useMemo(() => getModuleProgress(mapAnswers, mapProgress, courseItems, presentations), [
    mapProgress,
    mapAnswers,
    courseItems,
    presentations,
  ]);

  const { getLessonUserType } = useLessonAccess(course, courseItems);

  if (isEmpty(courseItems)) return null;

  const paidCourse = isPaidCourse(course);

  const lockedItems = isCourseProxyForMap(course)
    ? []
    : getLockedItems(isLoggedIn, numberOfPreviewLessons, courseItems, userCourseAccess);

  return (
    <List data-testid="course-item-list" disablePadding>
      {outcome === "paid" && (
        <PaymentOutcomeContainer>
          <PaymentOutcome outcome="paid" />
        </PaymentOutcomeContainer>
      )}
      {courseItems.slice(0, lockedItems.length === 0 ? undefined : numberOfPreviewLessons).map(item => {
        if (item.__typename === "CourseModule") {
          const expanded = item.id === expandedModuleId;
          return (
            <CourseModuleItem
              key={item.id}
              expanded={expanded}
              onToggleExpand={onToggleModule}
              courseModule={item}
              map={map}
              courseId={courseId}
              mapAnswers={mapAnswers}
              mapProgress={mapProgress}
              flashcardProgress={flashcardProgress}
              moduleProgress={moduleProgress ? moduleProgress.find(prog => prog.id === item.id) : undefined}
              getLessonUserType={getLessonUserType}
            />
          );
        }

        return (
          <ModuleItem
            key={item.id}
            courseItem={item}
            map={map}
            courseId={courseId}
            mapAnswers={mapAnswers}
            mapProgress={mapProgress}
            getLessonUserType={getLessonUserType}
            flashcardProgress={flashcardProgress}
          />
        );
      })}
      {!isEmpty(lockedItems) && (
        <div style={{ position: "relative" }} data-testid="locked-items">
          <SignUpOrPayOverlay
            onLogin={handleLogin}
            isLoggedIn={!!isLoggedIn}
            paidCourse={paidCourse}
            cancelled={outcome === "cancelled"}
            onPaymentRequired={onPaymentRequired}
          />
          {lockedItems.map(item => {
            if (item.__typename === "CourseModule") {
              const expanded = item.id === expandedModuleId;
              return (
                <CourseModuleItem
                  key={item.id}
                  expanded={expanded}
                  onToggleExpand={onToggleModule}
                  courseModule={item}
                  map={map}
                  courseId={courseId}
                  getLessonUserType={getLessonUserType}
                />
              );
            }

            return (
              <ModuleItem
                key={item.id}
                courseItem={item}
                map={map}
                courseId={courseId}
                getLessonUserType={getLessonUserType}
              />
            );
          })}
        </div>
      )}
    </List>
  );
};

export default CourseItemList;
