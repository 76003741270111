import React, { ChangeEvent } from "react";

export function resetScroll(element?: Element): void {
  if (element) {
    // eslint-disable-next-line no-param-reassign
    element.scrollTop = 0;
    // eslint-disable-next-line no-param-reassign
    element.scrollLeft = 0;
  }
}

export type InteractionEvent =
  | React.MouseEvent<Element, MouseEvent>
  | ChangeEvent<any>
  | KeyboardEvent
  | WheelEvent
  | ClipboardEvent;

export const preventEvent = (event: InteractionEvent | undefined): void => {
  if (event) {
    event.preventDefault();
    event.stopPropagation();
  }
};

export const stopPropagation = (event: InteractionEvent | undefined): void => {
  if (event) {
    event.stopPropagation();
  }
};
