import React from "react";
import { SvgIcon } from "./SvgIcon";

type Props = {
  width?: number;
  height?: number;
};

const style = { fill: "#4f4f4f" };
export const IconFlashCard = ({ width = 20, height = 20 }: Props): React.ReactElement => {
  const svgContent = (
    <g>
      <path d="M22.8 79.1l-2 .2c-5.6 0-10.4-4.1-11.4-9.7l-1-6.4v14.2c0 5 4 9.1 9 9.1h61.7c5 0 9.2-4 9.2-9v-9.3l-1.3.3-64.2 10.6z" />
      <path d="M96.4 53.8l-5.1-30.5a10.3 10.3 0 0 0-12-8.6L15.3 25.3c-5.7 1-9.5 6.3-8.6 12l5.1 30.5a10.3 10.3 0 0 0 12 8.6l64.1-10.7a10.3 10.3 0 0 0 8.6-11.9zM21 51c-.2-1.1.6-2.2 1.7-2.4L44.7 45c1.1-.2 2.2.6 2.4 1.7.2 1.2-.6 2.3-1.7 2.5l-21.9 3.6a2.1 2.1 0 0 1-2.4-1.7zm35.2 8.6l-30.8 5a2.1 2.1 0 0 1-.7-4.1l30.8-5a2.1 2.1 0 0 1 .7 4zm4-25.1l-38.8 6.4h-.3a2.1 2.1 0 0 1-.3-4.2l38.7-6.4c1.2-.2 2.2.6 2.4 1.7.2 1.2-.6 2.3-1.7 2.5z" />
    </g>
  );
  return <SvgIcon svgContent={svgContent} style={style} viewBox="0 0 100 100" width={width} height={height} />;
};
